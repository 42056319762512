<template>
  <div class="visialDesign">
    <header>
      <top-title
      title="影片製作"
      subTitle="">
      </top-title>
    </header>
    <page-main-body
      title="依照客戶需求與影片類型製作出符合您現行需要"
      subTitle="According to customer needs and film type, it can meet your current needs"
      paragraph1="影片分類非常多種，大從電視廣告規格，小至社群影片廣告。每一位客戶現行階段所要的有所不同，我們專業團隊會進行訪談而去了解你們，推薦最適合你們的解決方案。">
    </page-main-body>
    <h2>影片類別</h2>
    <type/>
    <h2 class="flowFlag">流程說明</h2>
    <flows/>
    <footer-view style="margin-top:500px"/>
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import footerView from "@/components/footerView.vue";
import type from "@/components/animations/type.vue";
import flows from "@/components/animations/flows.vue";

export default {
  name: "filming",
  components: {
    topTitle,
    pageMainBody,
    footerView,
    type,
    flows
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$parent.inPage = true;
  },
  destroyed() {
    this.$parent.inPage = false;
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight;
      if (st > $(".flowFlag").offset().top - hook * 0.6) {
        $(".flows").css({ display: "unset" });
        $("footer").css({ "margin-top": "0" });
      }
      $(".fade").each(function() {
        if (st > $(this).offset().top - hook * 0.6) {
          $(this).removeClass("fade");
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

header {
  background-image: url("../../assets/image/service/filming/topBanner.jpg");
}

.visialDesign {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
