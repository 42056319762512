<template>
  <div class="type">
    <div class="planet">
      <div class="fade planet1">
        <span> 商品短片類 </span>
        <font class="content1">
          - 產品使用流程<br>
          - 產品創意<br>
          - 影片互動<br>
          - 產品開箱
          <hr>
        </font>
      </div>
      <div class="fade planet2">
        <span> 商業影片類 </span>
        <font class="content2">
          - 腳本撰寫<br>
          - 場景、道具及演員籌備<br>
          - 出機拍攝<br>
          - 後製剪接
          <hr class="alterLine">
        </font>
      </div>
      <div class="fade planet3">
        <span> 動畫類 </span>
        <font class="content3">
          - 腳本編寫<br>
          - 素材繪製
          <hr>
        </font>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

$ballSize: 25vw;
$mainColors: $mainRed, $mainPurple, $mainBlue;

.fade {
  opacity: 0 !important;
}

.type {
  position: relative;
  margin: 220px 0;
  @media #{$phone} {
    margin-top: 30px;
    margin-bottom: 0px;
  }
  .planet {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    @media #{$phone} {
      flex-direction: column;
    }
    div {
      min-width: 250px;
      min-height: 250px;
      width: $ballSize;
      height: $ballSize;
      border-radius: 100%;
      background-color: transparent;
      border: 1px dashed #707070;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      transition: 0.5s;
      opacity: 1;
      @media #{$phone} {
        transform: translate(0, 0) !important;
        margin-bottom: 140px;
      }
      span {
        color: #fff;
        z-index: 2;
        @include font(medium);
      }
      font {
        position: absolute;
        bottom: $ballSize * 0.85;
        display: flex;
        flex-direction: column;
        @include font(small);
        &.content2 {
          bottom: -202px;
          hr {
            position: absolute;
            left: calc(50% - 1.5px);
            top: -130px;
          }
        }
        hr {
          width: 3px;
          height: 120px;
          background-color: #707070;
        }
        @media #{$phone} {
          flex-direction: column-reverse;
          bottom: unset;
          top: 220px;
          &.content2 {
            bottom: unset;
            hr {
              position: unset;
              top: 0;
            }
          }
          hr {
            width: 1px;
            height: 50px;
            top: 0;
          }
        }
      }
      @for $i from 1 to length($mainColors) + 1 {
        $color: nth($mainColors, $i);
        &:nth-child(#{$i}) {
          &::before {
            content: "";
            display: block;
            position: absolute;
            z-index: 2;
            width: $ballSize * 0.7;
            height: $ballSize * 0.7;
            min-width: 175px;
            min-height: 175px;
            background-color: $color;
            border-radius: 100%;
          }
        }
      }
    }
    .planet1 {
      transform: translate(35px, 0);
    }
    .planet2 {
      transform: translate(0, -70px);
    }
    .planet3 {
      transform: translate(-35px, 0);
    }
  }
}
</style>
