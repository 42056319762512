<template>
  <div class="flows" style="display:none">
    <div class="growingArea">
      <div class="status">
        <img src="@/assets/image/service/filming/grayBubble.svg">
        <img src="@/assets/image/service/filming/blueBubble.svg">
        <img src="@/assets/image/service/filming/purpleBubble.svg">
        <img src="@/assets/image/service/filming/pinkBubble.svg">
      </div>
      <div class="growBar">
        <div class="dot"></div>
        <div class="bar">
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
        <div class="arrow"></div>
      </div>
      <div class="lines">
        <div>
          <span>前置作業</span>
          <hr class="line1">
          <div class="arrow"></div>
        </div>
        <div>
          <span>拍攝執行</span>
          <hr class="line2">
          <div class="arrow"></div>
        </div>
        <div>
          <span>後製特效</span>
          <hr class="line3">
          <div class="arrow"></div>
        </div>
      </div>
      <div class="charts">
        <div class="chart1">
          <div class="content" v-for="(prepare, key) in prepares" :key="key">
            <h2>{{prepare.item}}</h2>
            <pre>{{prepare.detail}}</pre>
          </div>
        </div>
        <div class="chart2"></div>
        <div class="chart3">
          <div class="content" v-for="(after, key) in afters" :key="key">
            <h2>{{after.item}}</h2>
            <pre>{{after.detail}}</pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "flow",
  data() {
    return {
      prepares: [
        {
          item: "主題",
          detail: "商品 or 服務\nor 企業形象"
        },
        {
          item: "形式",
          detail: "實際拍攝 or 2D 動畫"
        },
        {
          item: "風格",
          detail: "配色、音樂節奏、調性"
        },
        {
          item: "文案",
          detail: "商業名稱、商品特色文\n字內容、服務文字內容"
        }
      ],
      afters: [
        {
          item: "A COPY",
          detail:
            "初剪 \n 無調色 & 後期特效 \n 1. 畫面節奏順序確認 \n 2. 文案內容確認    "
        },
        {
          item: "形式",
          detail: ""
        },
        {
          item: "B COPY",
          detail: "配色、音樂節奏、調性"
        }
      ]
    };
  }
};
</script>


<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

$arrowSize: 20px;
$dotSize: 30px;
$lineLength: 72px;
$barWidth: 10px;

$animationTime: 3s;
$barGradM: $mainBlue, $mainBlue 31%, #fff 31%, #fff 34%, $mainPurple 34%,
  $mainPurple 65%, #fff 65%, #fff 68%, $mainRed 68%, $mainRed 100%;

$firstStep: 22%;
$secondStep: 59%;
$thirdStep: 87%;
$steps: $firstStep, $secondStep, $thirdStep;
$stepsM: 15.5%, 49.5%, 83.5%;
$colors: $mainBlue, $mainPurple, $mainRed;
$cut1: 1%;
$cut2: 43.5%;
$cut3: 72%;
$cut4: 99%;
$cuts: $cut1, $cut2, $cut3, $cut4;

.flows {
  width: 80vw;
  position: relative;
  margin-top: 50px;
  .status {
    height: $barWidth;
    width: 100%;
    @for $i from 1 through length($cuts) {
      $cut: nth($cuts, $i);
      & img:nth-child(#{$i}) {
        left: calc(#{$cut} - 1%);
        animation: popOut#{$i} $animationTime * 2;
        @keyframes popOut#{$i} {
          0% {
            opacity: 0;
            transform: scale(0);
          }
          #{($cut) / 2 - 1} {
            opacity: 0;
            transform: scale(0);
          }
          #{$cut / 2} {
            opacity: 1;
            transform: scale(1.3);
          }
          #{($cut) / 2 + 5} {
            opacity: 1;
            transform: scale(1);
          }
        }
      }
    }
    img {
      position: absolute;
      height: 40px;
      top: -40px;
    }
  }
  .growBar {
    display: flex;
    align-items: center;
    .dot {
      width: $dotSize;
      height: $dotSize;
      border-radius: 100%;
      background-color: $mainBlue;
    }
    .bar {
      flex: 1;
      height: $barWidth;
      background-attachment: fixed;
      display: flex;
      @for $i from 1 through length($colors) {
        $color: nth($colors, $i);
        $bars: 44%, 27%, 25%;
        $bar: nth($bars, $i);
        & div:nth-child(#{$i}) {
          background-color: $color;
          width: $bar;
        }
      }
      .bar2,
      .bar3 {
        margin-left: 2%;
      }
      &::after {
        content: "";
        display: block;
        height: $barWidth;
        position: absolute;
        right: 0;
        background-color: #fff;
        animation: maskOut $animationTime;
        @keyframes maskOut {
          0% {
            width: calc(100% - #{$arrowSize});
          }
          44% {
            width: calc(55% - #{$arrowSize});
          }
          45% {
            width: calc(54% - #{$arrowSize});
          }
          71% {
            width: calc(28% - #{$arrowSize});
          }
          72% {
            width: calc(27% - #{$arrowSize});
          }
          100% {
            width: 0;
          }
        }
      }
    }
    .arrow {
      position: absolute;
      left: 100%;
      width: 0;
      height: 0;
      border-top: $arrowSize solid transparent;
      border-left: $arrowSize solid $mainRed;
      border-bottom: $arrowSize solid transparent;
      animation: colorTrans $animationTime;
      @keyframes colorTrans {
        0% {
          border-left: $arrowSize solid $mainBlue;
          left: 0;
          opacity: 0;
        }
        10% {
          opacity: 1;
        }
        44% {
          border-left: $arrowSize solid $mainBlue;
          left: calc(#{$dotSize} + 44%);
        }
        45% {
          border-left: $arrowSize solid $mainPurple;
        }
        71% {
          border-left: $arrowSize solid $mainPurple;
          left: calc(#{$dotSize} + 71%);
        }
        72% {
          border-left: $arrowSize solid $mainRed;
        }
        100% {
          border-left: $arrowSize solid $mainRed;
        }
      }
    }
  }
  .lines {
    position: absolute;
    top: 45px;
    display: flex;
    width: 100%;
    div:not(.arrow) {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      @for $i from 1 through length($steps) {
        $step: nth($steps, $i);
        $color: nth($colors, $i);
        &:nth-child(#{$i}) {
          left: $step;
          .arrow {
            border-top: $arrowSize solid $color;
            border-left: $arrowSize solid transparent;
            border-right: $arrowSize solid transparent;
          }
          hr {
            border-color: $color;
            animation: grow#{$i} $animationTime;
            @keyframes grow#{$i} {
              0% {
                height: 0;
              }
              #{$step - 10} {
                height: 0px;
              }
              #{$step + 23} {
                height: $lineLength;
              }
            }
          }
        }
      }
      span {
        position: absolute;
        white-space: nowrap;
        transform: translateY(-70px);
      }
      hr {
        width: 0px;
        height: $lineLength;
        background-color: transparent;
        border-style: dotted;
        border-width: 0 0 0 6px;
      }
      .arrow {
        display: block;
      }
    }
  }
  .charts {
    width: 100%;
    height: 800px;
    margin-top: 63px + $lineLength;
    div:not(.content) {
      position: absolute;
      width: 20%;
      height: calc(80% - 45px - #{$lineLength});
      padding: 0 20px;
      transform: translateX(calc(-50% + #{$arrowSize}));
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      @for $i from 1 through length($steps) {
        $step: nth($steps, $i);
        $color: nth($colors, $i);
        &:nth-child(#{$i}) {
          left: $step;
          border: 1px solid $color;
        }
      }
    }
    .chart2 {
      background-image: url("../../assets/image/service/filming/camara.jpg");
      background-size: cover;
      border: 0 !important;
    }
  }
  .content {
    @include font(smaller);
  }
}

@media only screen and (max-width: 768px) {
  $barHeight: 220vh;
  .flows {
    transform: translateX(60px);
    @media #{$phone} {
      transform: translateX(0);
    }
    margin-top: 90px;
    margin-bottom: 35px;
    .growingArea {
      height: $barHeight;
      .status {
        z-index: 3;
        img {
          z-index: 3;
          left: 0;
          &:nth-child(1) {
            top: -3%;
          }
          &:nth-child(2) {
            top: 30%;
          }
          &:nth-child(3) {
            top: 63%;
          }
          &:nth-child(4) {
            top: 98%;
          }
        }
      }
      .growBar {
        flex-direction: column;
        align-items: center;
        width: $arrowSize * 2;
        height: 100%;
        transform: translateX(5px);
        .dot {
          height: $dotSize * 0.5;
          width: $dotSize * 0.5;
        }
        .bar {
          width: $barWidth * 0.5;
          height: 100%;
          background: linear-gradient(to bottom, $barGradM);
          justify-content: center;
          flex-direction: column;
          &::after {
            left: $arrowSize - 3px;
            bottom: 0 - $arrowSize;
            width: $barWidth * 0.7;
            @keyframes maskOut {
              0% {
                height: calc(100% - #{$arrowSize});
              }
              30% {
                height: calc(69% - #{$arrowSize});
              }
              31% {
                height: calc(70% - #{$arrowSize});
              }
              63% {
                height: calc(36% - #{$arrowSize});
              }
              64% {
                height: calc(35% - #{$arrowSize});
              }
              100% {
                height: 0;
              }
            }
          }
        }
        .arrow {
          left: $arrowSize * 0.32;
          top: $barHeight;
          border-top: $arrowSize * 0.7 solid $mainRed;
          border-left: $arrowSize * 0.7 solid transparent;
          border-right: $arrowSize * 0.7 solid transparent;
          @keyframes colorTrans {
            0% {
              border-top: $arrowSize * 0.7 solid $mainBlue;
              top: 0;
              opacity: 0;
            }
            10% {
              opacity: 1;
            }
            27% {
              border-top: $arrowSize * 0.7 solid $mainBlue;
              top: 33%;
            }
            28% {
              border-top: $arrowSize * 0.7 solid $mainPurple;
            }
            63% {
              border-top: $arrowSize * 0.7 solid $mainPurple;
              top: 67%;
            }
            64% {
              border-top: $arrowSize * 0.7 solid $mainRed;
            }
            90% {
              border-top: $arrowSize * 0.7 solid $mainRed;
              top: $barHeight;
            }
          }
        }
      }
      .lines {
        top: 0;
        height: $barHeight;
        div:not(.arrow) {
          flex-direction: row;
          left: 0;
          @for $i from 1 through length($stepsM) {
            $step: nth($stepsM, $i);
            $color: nth($colors, $i);
            &:nth-child(#{$i}) {
              top: $step;
              .arrow {
                border-top: $arrowSize * 0.6 solid transparent;
                border-bottom: $arrowSize * 0.6 solid transparent;
                border-left: $arrowSize * 0.6 solid $color;
              }
              hr {
                @keyframes grow#{$i} {
                  0% {
                    width: 0;
                  }
                  #{$step - 10} {
                    width: 0px;
                  }
                  #{$step + 23} {
                    width: $lineLength * 0.5;
                  }
                }
              }
            }
          }
          span {
            top: 50%;
            left: -3px;
            width: 22px;
            white-space: pre-wrap;
          }
          hr {
            transform: translateX(44px);
            border-width: 2px 0 0 0;
            width: $lineLength * 0.5;
            height: 0;
          }
          .arrow {
            transform: translateX(54px);
          }
        }
      }
      .charts {
        margin-top: 0;
        height: $barHeight;
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        div:not(.content) {
          justify-content: center;
          position: relative;
          left: 60%;
          width: 50%;
          padding: 0;
          height: 28%;
        }
        div {
          .content {
            h2 {
              margin: 0;
            }
          }
        }
        .chart1 {
          top: 3%;
        }
        .chart2 {
          top: 9%;
        }
        .chart3 {
          top: 15%;
        }
      }
    }
  }
}
</style>
